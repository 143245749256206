import React, { useContext } from 'react';
import SignLayout from '../layouts/SignLayout';
import Company from '../containers/Company/Company';
import SEO from '../components/SEO/SEO';
import CountryContext from '../context/CountryContext'
let title = "Fair ridehailing provider l GOJO"
let description = "GOJO is committed to the mission of making comfortable, affordable, safe transportation accessible to all."

const CompanyPage = () => {
    const context = useContext(CountryContext)
    return (
        <SignLayout>
            <SEO title={title} description={description} />
            <Company countryCode={context.country} fleetId={context.fleetId}/>
        </SignLayout>
    )
}

export default CompanyPage;