import React, { useState } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { SupportEmails } from '../../constants/commonData'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import { getEmail } from '../../constants/commonFunctions'
import BrandGuidelines2021 from '../../../static/images/BrandGuidelines2021.pdf'
import BrandGuidelines2022 from '../../../static/images/BrandGuidelines2022.pdf'
import "react-multi-carousel/lib/styles.css";
import './company.scss'
// import TopStatic from '../components/TopStatic/TopStatic';
import TopStatic from '../../components/TopStatic/TopStatic'

const Company = ({ countryCode, fleetId }) => {
  let email = getEmail(countryCode, fleetId)
  return (
    <>
      <div>
        <img src={`../../../${countryCode}/images/company/banner_company.png`} className="imgBanerCompany d-none d-sm-block" />
        <img src={`../../../${countryCode}/images/company/banner_company_mobile.png`} className="imgBanerCompany d-block d-sm-none" />
      </div>
      <section id="about_company">
        <Container>
          <Row className="row_Company">
            <Col xs={12}>
              <div className="title_top">
                <img src={`../../../${countryCode}/images/company/bg_about.png`} className="" />
                <h1 className=""> <FormattedMessage id={"about.title"} /> </h1>
              </div>
            </Col>
            <Col cs={12}>
              <div className="grid_contact">
                <div className="content_about title_1" >
                  <h4><FormattedMessage id={"about.info_1"} /></h4>
                  <span></span>
                </div>
                <p className="ct_1">
                  <FormattedMessage id={"about.info_content_1"} />
                </p>
                <div className="content_about title_2" >
                  <h4><FormattedMessage id={"about.info_2"} /></h4>
                  <span></span>
                </div>
                <p className="ct_2">
                  <FormattedMessage id={"about.info_content_2"} />
                </p>
                <div className="content_about title_3" >
                  <h4><FormattedMessage id={"about.info_3"} /></h4>
                  <span></span>
                </div>
                <p className="ct_3">
                  <FormattedMessage id={"about.info_content_3"} />
                  <br />
                  {
                    (countryCode == 'my' || countryCode == 'in') &&
                    <a href={BrandGuidelines2021} download>
                      <FormattedMessage id={"about.download"} />
                    </a>
                  }
                  {
                    (countryCode != 'my' && countryCode != 'in') &&
                    <a href={BrandGuidelines2022} download>
                      <FormattedMessage id={"about.download"} />
                    </a>
                  }
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="contact_content">
                <div>
                  <img src={`../../../${countryCode}/images/company/contact.png`} className="imgBanerCompany" />
                </div>
                <div className="contact_bot">
                  <h2><FormattedMessage id={"about.title_contact"} /></h2>
                  <p>
                    <FormattedMessage
                      id={"about.info_contact"}
                      values={{ email: <a className="mailTo" href={`mailto:${email}`}>{email}</a> }}
                    />
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default injectIntl(Company)